import BadgeRecommended from './BadgeRecommended';
import { selectIcon } from '../templates/place';

const RouteOption = ({
  showRecommendedIcon,
  showRecommendedBadge,
  route,
  onSelect,
  showOrigin,
}) => {
  const { origin, destination } = route;

  const handleOnSelect = () => {
    const { origin, destination } = route;

    if (!origin && destination) {
      route.field = 'destination';
    }
    onSelect(route);
  };

  const icons = icon => {
    return (
      <div
        className="route-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon(icon, false),
        }}
      />
    );
  };

  return (
    <div className="route-option" onClick={handleOnSelect}>
      <div className="route-option-wrapper">
        {icons(showRecommendedIcon ? 'sparkle' : 'recent')}

        <div className="route-option-route">
          {showOrigin && (
            <p className="route-option-terminal">
              {origin.cityName} <span>{origin.display}</span>
            </p>
          )}
          <p className="route-option-terminal">
            {destination.cityName} <span>{destination.display}</span>
          </p>
        </div>
      </div>

      {showRecommendedBadge && <BadgeRecommended />}
    </div>
  );
};

export default RouteOption;
