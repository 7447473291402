import { useEffect, useState } from 'preact/hooks';
import { getRecentSearches } from '../../utils/recentTrips';
import { camelizeKeys } from 'humps';
import useUserPreferences from '../userPreferences';
import useWidgetConfig from '../WidgetConfig';
import usePlaces from '../Places';

const useRecommendedRoutes = ({ showRecentSearches, showPopularDestinations }) => {
  const [recentSearches, setRecentSearches] = useState({});
  const userPreferences = useUserPreferences();
  const { maxRecentSearches } = useWidgetConfig();
  const currentPlaces = usePlaces();

  const RECENT_SEARCHES = 'recentSearches';

  const { hasPreferredRoute } = userPreferences;

  const hasRecentSearches = Boolean(Object.keys(recentSearches).length);

  const setUpRecentSearches = () => {
    if (!showRecentSearches || !maxRecentSearches) return;

    const recentSearches = getRecentSearches(maxRecentSearches);
    const resentSearchesFormatted = recentSearches.map(recentSearch => ({
      ...recentSearch,
      origin: {
        ...recentSearch.origin,
        recentSearch: true,
      },
      destination: {
        ...recentSearch.destination,
        recentSearch: true,
      },
    }));

    const recentTripsCopy = [...resentSearchesFormatted];
    setRecentSearches({
      type: RECENT_SEARCHES,
      routes: camelizeKeys(recentTripsCopy),
    });
  };

  const createRecommendedList = () => {
    const recommendedRoutes = [];

    if (hasRecentSearches) {
      const recentSearchesCopy = { ...recentSearches, routes: [...recentSearches.routes] };

      const recentTripSameAsRecommended =
        hasPreferredRoute &&
        recentSearchesCopy.routes?.findIndex(
          route =>
            route.origin.id === userPreferences.originPlace.id &&
            route.destination.id === userPreferences.destinationPlace.id
        );

      if (recentTripSameAsRecommended > -1) {
        recentSearchesCopy.routes.splice(recentTripSameAsRecommended, 1);
      }
      recentSearchesCopy.routes = recentSearchesCopy.routes.slice(0, maxRecentSearches);
      if (hasPreferredRoute) recentSearches.routes = recentSearchesCopy.routes.slice(0, 2);
      if (recentSearchesCopy.routes?.length) recommendedRoutes.push(recentSearchesCopy);
    }

    if (showPopularDestinations && currentPlaces?.places?.length) {
      recommendedRoutes.push({
        type: 'popularDestinations',
        routes: camelizeKeys(
          currentPlaces.places.slice(0, 3).map(place => ({
            destination: {
              ...place,
              isPopularDestination: true,
            },
          }))
        ),
      });
    }
    return recommendedRoutes;
  };

  useEffect(() => {
    setUpRecentSearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add the recommended routes by profile too
  const recommendedRoutes = createRecommendedList();
  return {
    exists: Boolean(recommendedRoutes.length),
    recommendedRoutes,
  };
};

export default useRecommendedRoutes;
