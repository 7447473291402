import { decamelizeKeys } from 'humps';
import { RECENT_SEARCHES_LS } from './constants';

export function saveRecentSearches(origin, destination, maxRecentSearches) {
  const recentSearches = getRecentSearches(maxRecentSearches);
  const originExists = recentSearches.find(search => search.origin.id === origin.id);
  const destinationExists = recentSearches.find(search => search.destination.id === destination.id);
  if (originExists && destinationExists) return;
  if (recentSearches.length >= maxRecentSearches) recentSearches.pop();

  // Deleting extra props
  delete origin.preferred;
  delete destination.preferred;
  delete destination.isPopularDestination;

  recentSearches.unshift({
    origin: decamelizeKeys(origin),
    destination: decamelizeKeys(destination),
  });
  localStorage.setItem(RECENT_SEARCHES_LS, JSON.stringify(recentSearches));
}

export function getRecentSearches(maxRecentSearches) {
  const recentSearches = JSON.parse(localStorage.getItem(RECENT_SEARCHES_LS));
  return recentSearches?.splice(0, maxRecentSearches) || [];
}
