import { selectIcon } from '../templates/place';

const ReverseRoutesButton = ({ onReverse, reverseVariant }) => {
 
  return (
      <button className={`reverse-routes ${reverseVariant ? 'variant-reverse' : ''}`} onClick={onReverse}>
        <div className="reverse-routes-arrows"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: selectIcon('reverse', false),
          }}
        />
        <div />
      </button>
  );
};

export default ReverseRoutesButton;
