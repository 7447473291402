import { selectIcon } from '../templates/place';
import { createPortal } from 'preact/compat';
import getProductType from '../utils/getProductType';

const Modal = ({ children, onClose, title }) => {
  const isMobile = getProductType() === 'web-mobile';

  const modal = (
    <div className="sw-overlay" id="modalOverlay" onClick={onClose}>
      <div className="sw-modal">
        <div className="sw-modal-content">
          <div className="sw-modal-head">
            <p className="sw-modal-title">{title}</p>
            <div
              className="close"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: selectIcon('close', false),
              }}
              id="closeModalButton"
              onClick={onClose}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    const portalRoot = document.getElementById('reservamosPortal');
    return createPortal(modal, portalRoot);
  }

  return modal;
};

export default Modal;
