import addDate from './addDate';
import stringToDate from './stringToDate';

export default function validateDate(stringDate, maxDaysSearch, openTicketText) {
  // If date is boleto abierto, return true
  // Should also check in different languages
  if (stringDate === openTicketText) return true;

  // date in dd-mmm-yy format
  if (!/[0-9]{2}-[a-z]{3}-[0-9]{2}/i.test(stringDate)) return false;

  const dateToValidate = stringToDate(stringDate);

  // Getting the date without time to take the today date with the 00:00 hour
  const now = new Date((new Date()).setHours(0, 0, 0, 0));

  const maxDate = addDate(new Date(), maxDaysSearch);

  if (dateToValidate.getTime() < now.getTime()) return false;

  if (dateToValidate.getTime() > maxDate.getTime()) return false;

  return true;
}
