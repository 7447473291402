export default function decodePassengers (encodedPassengers) {
  const passengerTypes = encodedPassengers ? encodedPassengers.split('-') : ['A1'];

  return passengerTypes.reduce(
    (passengers, current) => {
      const type = current.substr(0, 1);
      const quantity = Number(current.substr(1));
      const decodedPassengers = passengers;

      if (!quantity) return decodedPassengers;

      switch (type) {
        case 'A':
          decodedPassengers.adult += quantity;
          break;
        case 'C':
          decodedPassengers.children += quantity;
          break;
        case 'I':
          decodedPassengers.infant += quantity;
          break;
        default:
          return passengers;
      }

      return decodedPassengers;
    },
    { adult: 0, children: 0, infant: 0 },
  );
}
