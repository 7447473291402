import { useEffect, useRef, useState } from 'preact/hooks';
import UserPreferencesContext from './UserPreferencesContext';
import userPreferencesService from '../../services/userPreferences';
import useGrowthBookFeatures from '../GrowthBook';
import createUrl from '../../utils/createUrl';
import http from '../../services/http';
import { camelizeKeys } from 'humps';

const UserPreferencesProvider = ({ children, config }) => {
  const growthbookFeatures = useGrowthBookFeatures();
  const [userPreferences, setUserPreferences] = useState({
    originTerminal: '',
    destinationTerminal: '',
    originCityName: '',
    destinationCityName: '',
    origin: '',
    destination: '',
    requested: profileNotNeeded,
    hasPreferredRoute: false,
    hasProfile: false,
  });
  const preferredRoutesSetup = useRef();
  const { profileEnabled, profileAutocomplete, profileUrl } = config;
  const profileNotNeeded = !profileEnabled || !profileUrl || !profileAutocomplete;

  const getAutoCompleteUrl = () => {
    const { sourceUrl, line, airline, transporter, forceHttps, filters } = config;
    const sourceUrlToUse = growthbookFeatures?.funnel_version?.sourceUrl || sourceUrl;
    const protocol = window.location.protocol.includes('https') || forceHttps ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${sourceUrlToUse}`);
    if (line) {
      autocompleteUrl.setQueryParam('line', line);
    } else if (airline) {
      autocompleteUrl.setQueryParam('airline', airline);
    } else if (transporter) {
      autocompleteUrl.setQueryParam('transporter', transporter);
    }
    if (filters) {
      autocompleteUrl.setQueryParam('filters', filters);
    }
    return autocompleteUrl;
  };

  const setPreferredMatchedPlaces = ({
    originTerminal,
    destinationTerminal,
    originCityName,
    destinationCityName,
    places,
  }) => {
    const compareTerminal = (a, b) => a.display?.toLowerCase() === b.toLowerCase();
    const compareCityName = (a, b) => a.cityName?.toLowerCase() === b.toLowerCase();

    let matchedOrigin = places.find(place => compareTerminal(place, originTerminal));
    if (!matchedOrigin)
      matchedOrigin = places.find(place => compareCityName(place, originCityName));

    /**
     * It is checked first by the display name as terminal, if it is not place found by the display name,
     * then it is checked by the city name
     */
    let matchedDestination =
      matchedOrigin && places.find(place => compareTerminal(place, destinationTerminal));
    if (matchedOrigin && !matchedDestination)
      matchedDestination = places.find(place => compareCityName(place, destinationCityName));
    if (matchedOrigin && matchedDestination) {
      /**
       * This class variables are set true to indicate that at the moment,
       * the preferred places have been showed and used by the user.
       */
      setUserPreferences({
        ...userPreferences,
        originPlace: matchedOrigin && camelizeKeys(matchedOrigin),
        destinationPlace: matchedDestination && camelizeKeys(matchedDestination),
        hasPreferredRoute: true,
        hasProfile: true,
      });
    } else {
      setUserPreferences({
        ...userPreferences,
        hasPreferredRoute: false,
        hasProfile: true,
      });
    }
    preferredRoutesSetup.current = true;
  };

  const requestPlaces = () => {
    return new Promise((resolve, reject) => {
      const sourceUrl = getAutoCompleteUrl().href;
      http
        .get(sourceUrl)
        .then(data => {
          if (data && data.length) {
            resolve(data);
          }
        })
        .catch(() => {
          // preferredRoutesSetup is set to true to avoid the request to the API again
          preferredRoutesSetup.current = true;
          reject();
        });
    });
  };

  const setUpPreferredTerminals = async ({
    originTerminal,
    destinationTerminal,
    originCityName,
    destinationCityName,
  }) => {
    try {
      const places = await requestPlaces();
      setPreferredMatchedPlaces({
        originTerminal,
        destinationTerminal,
        originCityName,
        destinationCityName,
        places,
      });
    } catch {
      setUserPreferences(prevState => ({
        ...prevState,
        hasPreferredRoute: false,
      }));
    }
  };

  async function getProfile() {
    try {
      const profile = await userPreferencesService.getUserProfile({ profileUrl });
      const { preferences } = profile;
      const {
        originTerminal,
        destinationTerminal,
        origin: originCityName,
        destination: destinationCityName,
      } = preferences;

      setUserPreferences({
        originTerminal,
        destinationTerminal,
        originCityName,
        destinationCityName,
        requested: true,
        hasProfile: true,
      });
    } catch {
      setUserPreferences(prevState => ({
        ...prevState,
        requested: true,
        hasProfile: false,
      }));
    }
  }

  useEffect(() => {
    if (profileNotNeeded) return;

    if (window.mixpanel && window.mixpanel.__loaded) {
      getProfile();
    } else {
      window.addEventListener('MixpanelReady', getProfile);
    }
    return () => {
      window.removeEventListener('MixpanelReady', getProfile);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      originTerminal,
      destinationTerminal,
      originCityName,
      destinationCityName,
    } = userPreferences;
    if (
      preferredRoutesSetup.current ||
      (!originTerminal && !destinationTerminal) ||
      (!originCityName && !destinationCityName)
    )
      return;
    setUpPreferredTerminals(userPreferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences]);

  return (
    <UserPreferencesContext.Provider value={userPreferences}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesProvider;
