import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/es-mx';
import encodePassengers from './encodePassengers';
import createUrl from './createUrl';
import serializeOccupancy from './serializeOccupancy';
import stringToDate from './stringToDate';

// Using custom parser to handle spanish dates
dayjs.extend(customParseFormat);

function resultsPath(searchParams, dataAttributes) {
  const { origin, destination, departureDate, returnDate, passengers } = searchParams;
  const { slug: originSlug } = origin;
  const { slug: destinationSlug } = destination;
  const { line, transporter } = dataAttributes;
  const encodedPassengers = encodePassengers(passengers);
  const path = ['/search', originSlug, destinationSlug, departureDate];

  if (returnDate) {
    path.push(returnDate);
  }

  path.push('p', encodedPassengers);

  if (line && !transporter) {
    path.push('departures', line);
  } else {
    path.push('providers');
  }

  return `//${dataAttributes.funnelUrl}${path.join('/')}`;
}

function formatDateForHotels(stringDate) {
  const parsedDate = stringToDate(stringDate);

  const addsZero = number => (number < 10 ? `0${number}` : number);

  return `${parsedDate.getFullYear()}-${addsZero(parsedDate.getMonth() + 1)}-${addsZero(
    parsedDate.getDate()
  )}`;
}

function serializeQueryParams({ utmParams, transporter, useUtmFor, lang }, searchType) {
  const utmTargets = useUtmFor.split(',').map(target => target.trim());

  const supportedLangs = {
    spanish: 'es',
    english: 'en',
  };

  if (!utmTargets.includes(searchType)) return '';

  const params = {
    transporter,
    utm_campaign: utmParams.campaign,
    utm_medium: utmParams.medium,
    utm_source: utmParams.source,
    utm_content: utmParams.content,
    lang: supportedLangs[lang],
  };

  const queryParams = Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

  return (queryParams.length ? '?' : '') + queryParams.join('&');
}

function removeSearchParams(url) {
  return createUrl(url).removeQueryParams(
    'origin',
    'destination',
    'departureDate',
    'returnDate',
    'passengers'
  ).queryString;
}

function makeOpenTicketUrl(origin, destination, multiOpenTicket) {
  if (multiOpenTicket === 'true') {
    const tomorrowDate = dayjs()
      .locale('es-mx')
      .format('DD-MMM-YY');
    return `/search/${origin.slug}/${destination.slug}/${tomorrowDate}/p/A1/departures/open`;
  }

  return `/search/open-ticket/${origin.slug}/${destination.slug}`;
}

function makePackagesUrl({ place }, config) {
  const { packagesUrl } = config;
  return `//${packagesUrl}/paquetes-hacia/${place.slug}`;
}

function makeHotelsUrl(fields, config) {
  const { place, startDate, endDate, guests } = fields;
  const { hotelsUrl } = config;
  const serializedOccupancy = serializeOccupancy(guests);
  const serializedStartDate = formatDateForHotels(startDate);
  const serializedEndDate = formatDateForHotels(endDate);

  // TODO: use hotelsResultsType to redirect to list or map
  return `//${hotelsUrl}/hotel-results?place=${place.slug}&startDate=${serializedStartDate}&endDate=${serializedEndDate}&occupancy=${serializedOccupancy}`;
}

function validateRoute(cities, origin, destination) {
  // Necesary to handle the prop object
  const helper = cities.replace(/'/g, '"');
  const jsonCities = JSON.parse(helper);

  const filteredCities = jsonCities.filter(
    city =>
      (city[0] === origin && city[1] === destination) ||
      (city[0] === destination && city[1] === origin)
  );

  return Boolean(filteredCities.length);
}

function makeSecondRedirectUrl(fields, config) {
  if (!config.redirectCities || !config.secondRedirectUrl || !config.secondRedirectEnabled) {
    return resultsPath(fields, config);
  }

  const cities = config.redirectCities;
  const secondRedirectUrl = config.secondRedirectUrl;
  const origin = fields.origin.citySlug;
  const destination = fields.destination.citySlug;
  const originSlug = fields.origin.slug;
  const destinationSlug = fields.destination.slug;
  const dateString = stringToDate(fields.departureDate);
  const departureDate = dayjs(dateString).format('YYYY-MM-DD');

  const isRouteToRedirect = validateRoute(cities, origin, destination);

  if (isRouteToRedirect) {
    return `${secondRedirectUrl}/trips?originCity=${originSlug}&destinationCity=${destinationSlug}&departureDate=${departureDate}&fromWidget=true`;
  }
  return resultsPath(fields, config);
}

function selectSearchTypePath(searchType, fields, config) {
  switch (searchType) {
    case 'bus':
      return resultsPath(fields, config);
    case 'packages':
      return makePackagesUrl(fields, config);
    case 'hotel':
      return makeHotelsUrl(fields, config);
    case 'secondRedirect':
      return makeSecondRedirectUrl(fields, config);
    default:
      return '';
  }
}

function makeHybridTripUrl(searchParams, hybridTrip, dataAttributes) {
  const { origin, destination, departureDate, returnDate, passengers } = searchParams;
  const { multiOpenTicket } = dataAttributes;
  const encodedPassengers = ['p', encodePassengers(passengers)];

  // Base search path
  const path = ['/search'];
  const tripSlugs = [origin.slug, destination.slug];

  // Format date to DD-MMM-YY
  const formatDate = date => {
    let validatedDate = date;
    if (!validatedDate.isValid()) {
      validatedDate = dayjs();
    }
    return validatedDate.locale('es-mx').format('DD-MMM-YY');
  };
  // Set departure and return dates
  // If departure date or return date is not set, set today date
  const dDate = dayjs(departureDate.toLowerCase(), 'DD-MMM-YY', 'es-mx');
  const rDate = dayjs(returnDate && returnDate.toLowerCase(), 'DD-MMM-YY', 'es-mx');

  // If the hybrid trip is departure and multi open ticket is enabled
  if (hybridTrip === 'departure' && multiOpenTicket === 'true' && !returnDate) {
    path.push(...tripSlugs, formatDate(dDate), ...encodedPassengers, 'departures', 'open');
  }

  // If the hybrid trip is departure and not multi open ticket
  if (
    hybridTrip === 'departure' &&
    (multiOpenTicket === 'false' || !multiOpenTicket) &&
    !returnDate
  ) {
    path.push('open-ticket', ...tripSlugs);
  }

  // If the hybrid trip is departure and not multi open ticket and return date is set
  if (
    hybridTrip === 'departure' &&
    (multiOpenTicket === 'false' || !multiOpenTicket) &&
    returnDate
  ) {
    path.push('open-ticket', ...tripSlugs, formatDate(rDate.subtract(1, 'day')), formatDate(rDate));
  }

  // If the hybrid trip is departure, multi open ticket is enabled and return date is set
  if (hybridTrip === 'departure' && multiOpenTicket === 'true' && returnDate) {
    path.push(
      ...tripSlugs,
      formatDate(rDate.subtract(1, 'day')),
      formatDate(rDate),
      ...encodedPassengers,
      'departures',
      'open'
    );
  }

  // If the hybrid trip is return and multi open ticket is enabled
  // Use same date for departure and return date to get the same results
  if (hybridTrip === 'return') {
    path.push(
      ...tripSlugs,
      formatDate(dDate),
      formatDate(dDate.add(1, 'day')),
      ...encodedPassengers,
      'departures?returnIsOpenTicket=true'
    );
  }

  // If the hybrid trip is both
  if (hybridTrip === 'both' && multiOpenTicket === 'true') {
    path.push(
      ...tripSlugs,
      formatDate(dDate),
      formatDate(dDate.add(1, 'day')),
      ...encodedPassengers,
      'departures',
      'open?returnIsOpenTicket=true'
    );
  }

  // If the hybrid trip is both and not multi open ticket
  if (hybridTrip === 'both' && (multiOpenTicket === 'false' || !multiOpenTicket)) {
    path.push('open-ticket', ...tripSlugs, `?returnIsOpenTicket=true`);
  }

  return path.join('/');
}

export default function resultsUrl(fields, config, searchType) {
  const { tripType, hybridTrip } = fields;

  // If the trip type is hybrid and it is one of departure, return or both
  // we need to prepare the url for it
  if (hybridTrip && hybridTrip !== 'none') {
    const path = makeHybridTripUrl(fields, hybridTrip, config);
    return `//${config.funnelUrl}${path}`;
  }

  if (tripType === 'openTicket') {
    const path = makeOpenTicketUrl(fields.origin, fields.destination, config.multiOpenTicket);
    return `//${config.funnelUrl}${path}`;
  }

  const path = selectSearchTypePath(searchType, fields, config);
  const query =
    config.forwardQuery === 'true'
      ? removeSearchParams(window.location.href)
      : serializeQueryParams(config, searchType);

  return `${path}${query}`;
}
