/**
 * Receives a passengers object with the type as the key and the quantity
 * as the value and returns a string with the passengers encoded for
 * query params or for the URL
 * ```
 * if type is queryParams:
 *    { adult: 2, child: 1, infant: 1 } => adult=2&child=1&infant=1
 * else:
 *    { adult: 2, child: 1, infant: 1 } => A2-C1-I1
 * ```
 * @method encodePassengers
 * @param {Object} passengers Passengers object
 * @return {String}
 */
export default function encodePassengers (passengers) {
  const passengerTypes = Object.keys(passengers);
  const encoded = [];

  passengerTypes.forEach((passengerType) => {
    const quantity = passengers[passengerType];

    if (quantity > 0) {
      encoded.push(`${passengerType.substr(0, 1).toUpperCase()}${quantity}`);
    }
  });

  return encoded.join('-');
}
